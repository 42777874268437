<template>
  <LoadingBar v-if="loading"></LoadingBar>
  <Page v-else :title="pageTitle">
    <v-form ref="form" v-model="valid" lazy-validation>
      <SourceEditFormFields></SourceEditFormFields>
      <v-row class="pa-5">
        <v-btn @click="cancel()" color="secondary">
          Cancel
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn @click="save()" color="primary">
          Save
        </v-btn>
      </v-row>
    </v-form>
  </Page>
</template>

<script>
import Page from "@/components/layout/Page";
import { mapActions, mapMutations, mapState } from "vuex";
import LoadingBar from "@/components/loaders/LoadingBar";
import SourceEditFormFields from "@/components/sources/SourceEditFormFields";

export default {
  name: "SourcesEdit",
  components: { LoadingBar, Page, SourceEditFormFields },
  data() {
    return {
      loading: true,
      valid: false
    };
  },
  computed: {
    ...mapState("sources", { source: "detailItem" }),
    ...mapState("farms", { farm: "detailItem" }),
    farmId() {
      return this.$route.params.farmId;
    },
    sourceId() {
      return this.$route.params.sourceId;
    },
    isNew() {
      return !this.source || !this.source.id;
    },
    pageTitle() {
      let title = this.isNew ? "Add Feed Source" : "Edit Feed Source";
      if (this.farm && this.farm.title) {
        title += ` for "${this.farm.title}"`;
      }
      return title;
    }
  },
  methods: {
    ...mapMutations("sources", ["clearDetailItem", "setDetailItem"]),
    ...mapActions("farms", ["getFarm"]),
    ...mapActions("sources", [
      "addFarmSource",
      "editFarmSource",
      "getFarmSource"
    ]),
    cancel() {
      this.clearDetailItem();
      this.backToBrowse();
    },
    backToBrowse() {
      this.$router.push(`/farms/${this.farmId}/sources`);
    },
    save() {
      if (!this.$refs.form.validate()) return false;
      let promise;
      if (this.isNew) {
        promise = this.addFarmSource(this.source);
      } else {
        promise = this.editFarmSource(this.source);
      }
      promise.then(() => {
        this.clearDetailItem();
        this.backToBrowse();
      });
    },
    loadFarm(id) {
      return new Promise((resolve, reject) => {
        if (id === this.farm.id) {
          resolve(true);
        } else
          this.getFarm(id)
            .then(response => resolve(response))
            .catch(error => reject(error));
      });
    },
    loadSource(id) {
      return new Promise((resolve, reject) => {
        if (id && id !== this.source.id) {
          this.getFarmSource({ farm_id: this.farmId, source_id: id })
            .then(response => {
              resolve(response);
            })
            .catch(error => {
              reject(error);
            });
        } else {
          if (!id && this.source.id) {
            this.clearDetailItem();
          }
          this.source.farm_id = this.farmId;
          resolve(true);
        }
      });
    }
  },
  mounted() {
    this.loading = true;
    Promise.all([this.loadFarm(this.farmId), this.loadSource(this.sourceId)])
      .then(() => {
        this.loading = false;
      })
      .catch(error => {
        console.error(error);
        this.backToBrowse();
      });
  }
};
</script>

<style scoped></style>
