<template>
  <SourcesEdit></SourcesEdit>
</template>

<script>
import SourcesEdit from "@/components/sources/SourcesEdit";
export default {
  name: "Edit",
  components: { SourcesEdit }
};
</script>

<style scoped></style>
